import { defineStore } from 'pinia'
import { queryBadgeData } from '@/api';


export const useReddotStore = defineStore('reddot', {
  // persist: true,
  state: () => {
    return {
      loginBonusRewardNum: 0, // 登录奖励
      inviteFriendToEarnNum: 0, // 邀请好友
      inboxUnread: 0, // 未读站内信
      taskRewardNum: 0, // 任务奖励
      inviteFriendEnergy: 0, // 邀请好友(充能)
    }
  },
  getters: {},
  actions: {
    fetchData(callback) {
      queryBadgeData().then(res => {
        let data = res.data || {};
        this.loginBonusRewardNum = data.loginBonusRewardNum;
        this.inviteFriendToEarnNum = data.inviteFriendToEarnNum;
        this.inboxUnread = data.letterUnreadNum;
        this.taskRewardNum = data.taskRewardNum;
        this.inviteFriendEnergy = data.inviteFriendEnergy;
        callback && callback();
      });
    },
    set(key, value = 0) {
      this[key] = value;
    },
    signUp() {
      if (this.loginBonusRewardNum > 0) {
        this.loginBonusRewardNum = this.loginBonusRewardNum - 1;
      } else {
        this.loginBonusRewardNum = 0;
      }
    },
    readMessage() {
      if (this.inboxUnread > 0) {
        this.inboxUnread = this.inboxUnread - 1;
      } else {
        this.inboxUnread = 0;
      }
    },
    inviteSpins() {
      if (this.inviteFriendToEarnNum > 0) {
        this.inviteFriendToEarnNum = this.inviteFriendToEarnNum - 1;
      } else {
        this.inviteFriendToEarnNum = 0;
      }
    },
    fillEnergy() {
      if (this.inviteFriendEnergy > 0) {
        this.inviteFriendEnergy = this.inviteFriendEnergy - 1;
      } else {
        this.inviteFriendEnergy = 0;
      }
    },
    getTaskReward() {
      if (this.taskRewardNum > 0) {
        this.taskRewardNum = this.taskRewardNum - 1;
      } else {
        this.taskRewardNum = 0;
      }
    }
  }
})
