import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup-main" }
const _hoisted_2 = { class: "popup-header" }
const _hoisted_3 = { class: "popup-title" }
const _hoisted_4 = { class: "popup-content" }
const _hoisted_5 = { class: "input-form" }
const _hoisted_6 = { class: "input-label" }
const _hoisted_7 = { class: "input-row" }
const _hoisted_8 = { class: "bank-list" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "bank-item-title" }
const _hoisted_11 = { class: "input-box" }
const _hoisted_12 = {
  key: 0,
  class: "input-field"
}
const _hoisted_13 = {
  key: 1,
  class: "input-field placeholder-text"
}
const _hoisted_14 = { class: "input-label" }
const _hoisted_15 = { class: "input-row" }
const _hoisted_16 = { class: "input-box" }
const _hoisted_17 = { class: "input-label" }
const _hoisted_18 = { class: "input-row" }
const _hoisted_19 = { class: "input-box" }
const _hoisted_20 = { class: "tip-wrap flex align-start" }
const _hoisted_21 = { class: "tip-text" }

import { ref, reactive, computed, watch, inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia';
import { useHelpTool } from '@/hooks';
import { usePopupStore } from "@/store";
import { queryWithdrawBank, saveWithdrawAccount } from '@/api';


export default {
  __name: 'BindWallet',
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const MyToast = inject('myToast')

const { t } = useI18n()
const { imageByTheme } = useHelpTool()

const popupStore = usePopupStore();
const { walletAccount } = storeToRefs(popupStore);

const selfRef = ref(null);
const bankList = ref([]);
const activeBank = ref(null);
const showBankSelect = ref(false);
const form = reactive({
  account: '',
  name: '',
});

const isGcashOrMaya = computed(() => {
  return activeBank.value?.code === 'gcash' || activeBank.value?.code === 'maya';
})

const saveDisabled = computed(() => {
  if(isGcashOrMaya.value) {
    return form.account.length < 10 || form.name.length < 2;
  } else {
    return form.account.length < 0 || form.name.length < 2;
  }
})

watch(activeBank, (newValue, oldValue) => {
  if(oldValue && newValue) {
    form.account = '';
  }
})

onMounted(() => {
  fetchWithdrawBank();
})
const fetchWithdrawBank = () => {
  queryWithdrawBank().then(res => {
    bankList.value = res.data || [];
    if (walletAccount.value) {
      form.name = walletAccount.value.name;
      form.account = walletAccount.value.account;
      activeBank.value = bankList.value.find(item => item.code === walletAccount.value.bank)
    }
  })
}

const doSave = () => {
  saveWithdrawAccount({
    bank: activeBank.value.code,
    name: form.name,
    account: form.account
  }).then(res => {
    MyToast({ type: 'success', message: t('notify.operateSuccess') });
    popupStore.closeBindWallet();
  })
}

return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_popover = _resolveComponent("van-popover")
  const _component_van_field = _resolveComponent("van-field")
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_button = _resolveComponent("van-button")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "selfRef",
    ref: selfRef,
    class: "bind-wallet-popup"
  }, [
    _createVNode(_component_van_popup, {
      show: true,
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('finance.accountInformation')), 1),
            _withDirectives(_createVNode(_component_van_image, {
              class: "close-icon",
              src: _unref(imageByTheme)('close.png'),
              onClick: _cache[0] || (_cache[0] = $event => (emit('onClose')))
            }, null, 8, ["src"]), [
              [_directive_audio, void 0, "close"]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('finance.chooseBank')) + ":", 1),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_van_popover, {
                  show: showBankSelect.value,
                  "onUpdate:show": _cache[1] || (_cache[1] = $event => ((showBankSelect).value = $event)),
                  teleport: selfRef.value
                }, {
                  reference: _withCtx(() => [
                    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (activeBank.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(activeBank.value.name), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t('placeholder.chooseBank')), 1))
                    ])), [
                      [_directive_audio]
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bankList.value, (item) => {
                        return _withDirectives((_openBlock(), _createElementBlock("div", {
                          class: "bank-item",
                          key: item.product,
                          onClick: $event => (activeBank.value = item, showBankSelect.value = false)
                        }, [
                          _createElementVNode("div", _hoisted_10, _toDisplayString(item.name), 1)
                        ], 8, _hoisted_9)), [
                          [_directive_audio]
                        ])
                      }), 128))
                    ])
                  ]),
                  _: 1
                }, 8, ["show", "teleport"])
              ]),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('label.name')) + ":", 1),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_van_field, {
                    modelValue: form.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((form.name) = $event)),
                    modelModifiers: { trim: true },
                    class: "input-field",
                    maxlength: "32"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('label.account')) + ":", 1),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_van_field, {
                    modelValue: form.account,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((form.account) = $event)),
                    modelModifiers: { trim: true },
                    class: "input-field",
                    type: "digit",
                    maxlength: isGcashOrMaya.value ? 11 : 32,
                    placeholder: isGcashOrMaya.value ? _ctx.$t('placeholder.mobilePhone') : ''
                  }, null, 8, ["modelValue", "maxlength", "placeholder"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_van_icon, {
                class: "tip-icon",
                name: "warning"
              }),
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('finance.accountTip')), 1)
            ]),
            _withDirectives((_openBlock(), _createBlock(_component_van_button, {
              class: "save-btn",
              onClick: doSave,
              disabled: saveDisabled.value
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.save')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])), [
              [_directive_audio]
            ])
          ])
        ])
      ]),
      _: 1
    })
  ], 512))
}
}

}