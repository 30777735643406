export const usePWA = () => {
  const initPWA = () => {
    window.addEventListener('load', function() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js').then(function(registration) {
          // 注册成功
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
          // 注册失败
          console.log('ServiceWorker registration failed: ', err);
        });
      }
    });

    window.addEventListener("beforeinstallprompt",function (e) {
      // 阻止 Chrome 67 及更早版本自动显示提示
      e.preventDefault();
      console.log("deferredPrompt: ", e);
      // 保存事件以便稍后使用
      window.deferredPrompt = e;
    });
  }

  const installPWA = () => {
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          console.log('User accepted the prompt');
        } else {
          console.log('User dismissed the prompt');
        }
        window.deferredPrompt = null;
      });
    }
  }

  return { 
    initPWA,
    installPWA,
  }
}