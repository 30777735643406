import axios from "@/api/axios";

// 欢迎页面数据
export const queryWelcomeData = data => axios({ url: "/api/page/data/LR",  data });

//登录
export const login = data => axios({ 
  url: "/api/user/login",
  method: 'post',
  data,
});

// 游客登录
export const guestLogin = data => axios({ 
  url: "/api/user/guest",
  method: 'post',
  data,
});

// 注册
export const register = data => axios({ 
  url: "/api/user/register",
  method: 'post',
  data,
});
export const querySignupAreaCode = data => axios({ url: "/api/signup/phone/area/code",  data });
export const registerOTP = data => axios({ 
  url: "/api/user/sms/send/register",
  method: 'post',
  data,
});

// 用户名注册
export const customRegister = data => axios({ 
  url: "/api/user/custom/register",
  method: 'post',
  data,
});

export const saveInviteCode = data => axios({ 
  url: "/api/user/register/flag",
  method: 'post',
  data,
});

// 绑定手机号
export const bindAccount = data => axios({ 
  url: "/api/user/bind",
  method: 'post',
  data,
});
export const bindAccountOTP = data => axios({ 
  url: "/api/user/sms/send/bind",
  method: 'post',
  data,
});

// 登出
export const logout = data => axios({ 
  url: "/api/user/logout",
  method: 'post',
  data,
});

// 忘记密码
export const forgetPwdOTP = data => axios({ 
  url: "/api/user/sms/send/resetLoginPwd",
  method: 'post',
  data,
});
export const forgetPwd = data => axios({ 
  url: "/api/reset/login/pwd",
  method: 'post',
  data,
});
// 修改密码
export const modifyLoginPwd = data => axios({ 
  url: "/api/user/modifyPasswordLogin",
  method: 'post',
  data,
});
// 修改交易密码
export const modifyTradePwd = data => axios({ 
  url: "/api/user/modifyPasswordTrade",
  method: 'post',
  data,
});

// 重置交易密码
export const resetTradePwdOTP = data => axios({ 
  url: "/api/user/sms/send/resetTradePwd",
  method: 'post',
  data,
});
export const resetTradePwd = data => axios({ 
  url: "/api/reset/trade/pwd",
  method: 'post',
  data,
});


// 配置
export const queryConfig = data => axios({ url: "/api/config/fetch",  data });
export const queryAppVersion = data => axios({ url: "/api/app/version",  data });

// 分享
export const shareSocialMedia = data => axios({ url: "/api/user/share",  data });
export const successShared = data => axios({ url: "/api/user/share/success",  data });

// 关注
export const followSocialMedia = data => axios({ url: "/api/user/follow",  data });

// 用户信息
export const getUserInfo = data => axios({ url: "/api/user/info",  data });
export const getBalance = data => axios({ url: "/api/balance",  data });
export const queryAvatar = data => axios({ url: "/api/material/avatar",  data });
export const queryBadgeData = data => axios({ url: "/api/badge/data",  data });
export const updateUserInfo = data => axios({ 
  url: "/api/user/modify",
  method: 'post',
  data,
});

// vip等级
export const queryVipInfo = data => axios({ url: "/api/vip/fetch",  data });
export const queryVipRecord = data => axios({ url: "/api/vip/fetch/vipLog",  data });
export const upgradeVip = data => axios({ 
  url: "/api/vip/upgrade",
  method: 'post',
  data,
});

// deposit
export const queryDepositProduct = data => axios({ url: '/api/recharge/method', data});
export const queryDepositBank = data => axios({ url: '/api/recharge/bank', data });
export const queryDepositAmount = data => axios({ url: '/api/recharge/amount', data});
export const preCreateDeposit = data => axios({ 
  url: "/api/recharge/preCreate",
  method: 'post',
  data,
});
export const createDeposit = data => axios({ 
  // url: "/api/recharge/create",
  url: "/api/recharge/build",
  method: 'post',
  data,
});
export const queryDepositOrder = data => axios({ url: '/api/recharge/fetch', data});
export const queryCoupon = data => axios({ url: '/api/coupon/fetch', data});

// withdraw
export const queryWithdrawBank = data => axios({ url: '/api/withdraw/bankList', data });
export const queryWithdrawAccount = data => axios({ url: '/api/user/bankCard/wallet/account', data });
export const saveWithdrawAccount = data => axios({ 
  url: "/api/user/bankCard/create",
  method: 'post',
  data,
});
export const createWithdraw = data => axios({ 
  url: "/api/withdraw/create",
  method: 'post',
  data,
});
export const queryWithdrawOrder = data => axios({ url: '/api/withdraw/fetch', data});

//game
export const queryGameType = data => data.hasLogin ? axios({ url: "/api/game/type",  data }) : axios({ url: "/api/open/game/type",  data });
export const queryGameProvider = data => data.hasLogin ? axios({ url: "/api/manuf/fetch",  data }) : axios({ url: "/api/open/manuf/fetch",  data });
export const queryGameList = data =>data.hasLogin ? axios({ url: "/api/game/fetch",  data }) : axios({ url: "/api/open/game/fetch",  data });
export const queryGameHot = data => data.hasLogin ? axios({ url: "/api/game/fetch/hot",  data }) : axios({ url: "/api/open/game/fetch/hot",  data });
export const queryGameNew = data => data.hasLogin ? axios({ url: "/api/game/fetch/live",  data }) : axios({ url: "/api/open/game/fetch/live",  data });
export const searchGame = data => axios({ url: "/api/open/game/search",  data });
export const querySearchDiscover = data => axios({ url: "/api/open/game/searchDiscover",  data });
export const queryGameCollect = data =>  axios({ url: "/api/user/game/collect/fetch",  data });
export const queryGameHelper = data =>  axios({ url: "/api/game/helper",  data, config: { noLoading: true } });
export const queryGameRecord = data =>  axios({ url: `/api/game/record/${data.type}`,  data});
export const collectGame = data => axios({ 
  url: '/api/user/game/collect',
  method: 'post',
  data,
});
export const cancelCollectGame = data => axios({ 
  url: '/api/user/game/collect/del',
  method: 'post',
  data,
});
export const enterGame = data => axios({ 
  url: '/api/game/getUrl',
  method: 'post',
  data,
});


// 流水 record
export const queryRecords = data => axios({ url: "/api/user/balanceJournal/fetch",  data });
export const queryRecordType = data => axios({ url: "/api/user/balanceJournal/type",  data });

// 公告 events
export const queryEvent = data => axios({ url: "/api/open/banner/info",  data });

// 首页信息
export const queryWithdrawMessage = data => axios({ url: "/api/open/withdraw/message",  data });

// 站内信 inbox
export const queryMessage = data => axios({ url: "/api/letter",  data });
export const queryMessageType = data => axios({ url: "/api/letter/type",  data });
export const readMessage = data => axios({ 
  url: '/api/letter/read',
  method: 'post',
  data,
});
export const deleteMessage = data => axios({ 
  url: '/api/letter/delete',
  method: 'post',
  data,
});
export const claimMessageReward = data => axios({ 
  url: '/api/letter/claim',
  method: 'post',
  data,
});


// 签到活动
export const querySignUpActivity = data => axios({ url: "/api/signUp/fetch",  data });
export const queryBattlePassActivity = data => axios({ url: "/api/battlePass/fetch",  data });
export const joinSignUp = data => axios({ 
  url: '/api/signUp/join',
  method: 'post',
  data,
});
export const signUp = data => axios({ 
  url: '/api/signUp/sign',
  method: 'post',
  data,
});
export const signBattlePass = data => axios({ 
  url: '/api/battlePass/sign',
  method: 'post',
  data,
});

// 大转盘
export const queryLuckyWheel = data => axios({ url: "/api/coupon/fetchLuckyWheel",  data });
export const queryCouponRecord = data => axios({ url: "/api/coupon/fetchRecord",  data });
export const useCoupon = data => axios({ 
  url: '/api/coupon/use',
  method: 'post',
  data,
});

// task 里程碑
export const queryTask = data => axios({ url: "/api/task/fetch",  data });
export const receiveTask = data => axios({ 
  url: '/api/task/receive',
  method: 'post',
  data,
});
export const getTaskReward = data => axios({ 
  url: '/api/task/getReward',
  method: 'post',
  data,
});
export const getTaskBoxReward = data => axios({ 
  url: '/api/task/getBoxReward',
  method: 'post',
  data,
});

// Exchange
export const queryBetMall = data => axios({ url: '/api/mallExchange/info', data });
export const mallExchange = data => axios({ 
  url: '/api/mallExchange/order',
  method: 'post',
  data,
});

// 绑定上级
export const bindCode = data => axios({ 
  url: `/api/user/bind/${data.code}`,
  method: 'post',
  data,
});

// 拉新活动(大转盘)
export const queryInviteActivity = data => axios({ url: `/api/activity/invite/info`,  data });
export const queryInviteRecord = data => axios({ url: `/api/activity/invite/reward/record`,  data });
export const spinInviteBonus = data => axios({ 
  url: '/api/activity/invite/spin',
  method: 'post',
  data,
});

// 拉新活动(能量)
export const queryEnergyfill = data => axios({ url: "/api/energyfill/fetch",  data });
export const queryEnergyInviteRecord = data => axios({ url: "/api/user/effective/record/fetch",  data });
export const fillEnergy = data => axios({ 
  url: '/api/energyfill/fill',
  method: 'post',
  data,
});
export const claimEnergyReward = data => axios({ 
  url: '/api/energyfill/claim',
  method: 'post',
  data,
});

// 兑换码
export const useRedeem = data => axios({ 
  url: '/api/redemption/code/use',
  method: 'post',
  data,
});
