import { showToast } from 'vant';
import 'vant/es/toast/style';

export default param => {
  let { type, message } = param;
  if (typeof param === "string" ) {
    message = param;
  }
  switch (type){
    case 'success':
      showToast({
        message,
        icon: '/images/notify-success.png',
      });
      break;
    case 'danger':
      showToast({
        message,
        icon: '/images/notify-error.png',
      });
      break;
    default:
      showToast(message);
      break;
  }
};