import { defineStore } from 'pinia';
import { storage } from '@/utils/storage';
import { router } from '@/router';
import { useReddotStore } from '@/store';
import { getUserInfo, updateUserInfo, modifyTradePwd, modifyLoginPwd, logout } from '@/api';
  
export const useUserStore = defineStore('user', {
  // persist: true,
  state: () => {
    return {
      uid: 0, // 用户ID
      username: '', // 用户账号
      nickname: '', // 用户昵称
      gender: 'male', // 性别
      phone: '', // 手机号
      avatar: '',
      invitationCode: '', //邀请码
      vip: 0,
      bind: true,
      isNullPasswordTrade: false,
      hasSignUp: false,
      registrationCode: '', //上级邀请码
      guest: false, // 是否游客
      loginInit: false, // 是否需要登录初始化(弹窗等)
    }
  },
  getters: {},
  actions: {
    clearData() {
      this.$reset()
      storage.remove('userInfo')
    },
    logout() {
      logout().then(()=> {}) 
      setTimeout(() => {
        this.clearData();
        useReddotStore().$reset();// 清空红点数量
        router.replace({ name: 'game' })
      }, 100);
    },
    setLoginInit(loginInit) {
      this.loginInit = loginInit;
    },
    setUserInfo(userData) {
      storage.set('userInfo', userData)
      this.uid = userData.uid;
      this.username = userData.username;
      this.nickname = userData.nickname;
      this.phone = userData.phone;
      this.avatar = userData.avatar || '';
      this.gender = userData.gender || 'male';
      this.invitationCode = userData.invitationCode || '';
      this.vip = userData.vip || 0;
      this.bind = userData.bind;
      this.isNullPasswordTrade = userData.isNullPasswordTrade;
      this.hasSignUp = userData.hasSignUp || false;
      this.registrationCode = userData.registrationCode || '';
      this.guest = userData.guest || false;
    },
    loadUserInfo(callback, errorCallback) {
      getUserInfo().then((res) => {
        let userData = res.data || {}
        this.setUserInfo(userData);
        callback && callback();
      }, () => {
        errorCallback && errorCallback();
      })
    },
    modifyUserInfo(data) {
      updateUserInfo(data).then((res) => {
        this.setUserInfo({ ...this.$state, ...data});
      })
    },
    updateTransactionPwd(data, callback) {
      modifyTradePwd(data).then((res) => {
        this.setUserInfo({ ...this.$state, isNullPasswordTrade: false });
        callback && callback();
      })
    },
    updateLoginPwd(data, callback) {
      modifyLoginPwd(data).then((res) => {
        callback && callback();
      })
    },
  }
})
